.CreateListForm {
    position: absolute;
    top: 30vh;
    width: 50vw;
    left: 24.75vw;
    height: 60vh;
    border-radius: 1vw;
    background-color: #FFF;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: grid;
    padding: 0.5vw;
}

.CreateListForm_label {
    font-size: 1.8vw;
    font-weight: 600;
    grid-row: 1;
    grid-column: 2;
    justify-self: center;
}

.CreateListForm_close {
    grid-row: 1;
    grid-column: 3;
    color: #fff;
    background-color: #1DD1A1;
    border: none;
    width: 2vw;
    border-radius: 0.1vw;
    justify-self: center;
}

.CreateListForm_input_list_element {
    grid-row: 2;
    grid-column: 2;
    border: 0;
    border-bottom: 2px solid #CFD8DC;
    width: 10vw;
    justify-self: center;
}

.CreateListForm_input_list_element:focus {
    border: 0;
    border-bottom: 2px solid #1DD1A2;
}


.CreateListForm_addElement_button {
    color: #fff;
    background-color: #1DD1A1;
    border: 0;
    padding: auto;
    grid-row: 2;
    grid-column: 3;
    align-self: center;
    justify-self: start;
    font-size: 1vw;
    border-radius: 0.2vw;
    width: 5vw;
}

.CreateListForm_groceryItems {
    grid-column-start: 2;
    grid-column-end: 3;
    overflow-y: auto;
}

.CreateListForm_input_participants {
    grid-row: 4;
    border: 0;
    border-bottom: 2px solid #CFD8DC;
    width: 10vw;
    grid-column: 2;
    justify-self: center;
}

.CreateListForm_participants {
    grid-row: 5;
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    grid-column-start: 2;
    grid-column-end: 3;
    max-height: 10vh;
    overflow-y: auto;
}

.CreateListForm_input_create_list_button {
    border: 0;
    grid-row: 6;
    grid-column: 2;
    height: 4vh;
    background-color: #1DD1A2;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 4px;
    border-radius: 0.5vw;
    justify-self: center;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {


    .CreateListForm {
        width: 90vw;
        left: 3vw;
        margin-top: -8vh;
        padding: 2vw;
    }

    .CreateListForm_label {
        font-size: 6vw;
        grid-row: 1;
        grid-column-start: 1;
        grid-column-end: 4;
        justify-self: center;
    }

    .CreateListForm_close {
        margin: auto;
        font-size: 5vw;
        width: auto;
        top: 5vh;
        right: 2vw;
        position: absolute;
    }

    .CreateListForm_input_list_element {
        width: 60%;
    }

    .CreateListForm_addElement_button {

        font-size: 6vw;
        width: auto;
    }

    .CreateListForm_input_participants {
        width: 60%;
    }

    .CreateListForm_participants {
        grid-column-start: 1;
        grid-column-end: 4;
        max-height: 15vh;
    }

    .CreateListForm_groceryItems {
        max-height: 20vh;
    }

    .CreateListForm_input_create_list_button {
        font-size: 4vw;
        grid-column-start: 1;
        grid-column-end: 4;
    }


}