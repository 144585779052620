.GroceryListContainer {
    border: 3px solid #1DD1A1;
    border-radius: 3px;
    padding: 1vw;
    width: 70vw;
    margin-left: 15vw;
    display: grid;
    grid-gap: 1vw;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto auto auto;
    max-height: 60vh;
    overflow-y: scroll;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.GroceryListContainer_Chargement {
    width: 90%;
    display: block;
    margin-left: 5%;
    color: #1DD1A1;
    text-align: center;
    font-size: 2.5vh;
}

.GroceryListContainer_noListYet {
    width: 90%;
    display: block;
    margin-left: 5%;
    color: #1DD1A1;
    text-align: center;
    font-size: 2.5vh;
}

@media only screen and (max-width: 600px) {

    .GroceryListContainer {
        width: 95vw;
        margin-left: 0.5vw;
        display: block;
        max-height: 80vh;
    }

}