.LiveShopping {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    position: absolute;
    top: 25vh;
    width: 25vw;
    left: 37.25vw;
    height: 50vh;
    border-radius: 1vw;
    background-color: #FFF;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 1vw;
    padding: 0.5vw;
}

.LiveShopping_close {
    grid-row: 1;
    grid-column: 3;
    width: 25%;
    height: 3vh;
    margin-left: 37.25%;
    color: #fff;
    background-color: #1DD1A1;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 6px;
    border: none;
    font-weight: 600;
    border-radius: 0.2vw;
}

.LiveShopping_title {
    grid-row: 1;
    grid-column: 2;
    font-weight: 600;
    font-size: 1.2vw;
}

.LiveShopping_groceryItemList {
    grid-row: 2;
    padding: 0.5vh;
    row-gap: 1vh;
    grid-column-start: 1;
    grid-column-end: 4;
    padding: 0;
    overflow-y: auto;
    overflow-x: none;
    display: grid;
    max-height: 20vh;
}

.LiveShopping_groceryItemList>.GroceryItem {
    display: grid;
    width: 99%;
}

.LiveShopping_groceryItemList>.GroceryItem>.GroceryItemName {
    grid-column: 1;
    justify-self: center;
    overflow: hidden;
    overflow-wrap: anywhere;
    height: fit-content;
    align-self: center;
    padding: 0.5vh;
}

.LiveShopping_groceryItemList>.GroceryItem>.GroceryQuantity {
    grid-column: 2;
    justify-self: center;
}

.LiveShopping_groceryItemList>.GroceryItem>.GroceryItem_by_it {
    grid-column: 3;
    color: #fff;
    background-color: #1DD1A1;
    border: none;
    font-weight: 600;
    padding: auto;
    justify-self: center;
    border-radius: 0.2vw;
    height: 4vh;
    align-self: center;
}

.LiveShopping_input_lieu {
    grid-row: 4;
    grid-column: 2;
}

.LiveShopping_input_montant {
    grid-row: 5;
    grid-column: 2;
    margin-top: 20%;
}

.LiveShopping_endShopping {
    color: #fff;
    background-color: #1DD1A1;
    border: none;
    font-weight: 600;
    grid-row: 3;
    grid-column: 2;
    padding: auto;
    border-radius: 0.2vw;
    font-size: 2vh;
}

.LiveShopping_validate {
    grid-row: 6;
    grid-column: 2;
    width: 70%;
    margin-left: 15%;
    height: 35%;
    margin-top: 40%;
    color: #fff;
    background-color: #1DD1A1;
    border: none;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {


    .LiveShopping_groceryItemList {
        max-height: 60vh;
    }


    .LiveShopping {
        width: 90vw;
        left: 5vw;
        margin-bottom: 2vh;
        height: 70vh;
        padding-bottom: 1.5vh;
    }

    .LiveShopping_title {
        font-size: 6vw;
        grid-column-start: 2;
        grid-column-end: 4;
        width: 80%;
    }

    .LiveShopping_close {
        margin: auto;
        font-size: 5vw;
        width: auto;
        margin-right: 2vw;
    }

    .LiveShopping_validate {
        font-size: 5vw;
    }

}