.loginForm {
    width: 10vw;
    border: 2px solid #1DD1A1;
    border-radius: 5px;
    background-color: #FFF;
    margin-left: 40vw;
    margin-top: 10%;
    height: 30vh;
    padding: 2vw;
    color: #1DD1A1;
    display: grid;
    grid-gap: 1vw;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto auto auto;
}

.loginForm_account_icone {
    grid-column-start: 2;
    grid-row-start: 1;
}

.loginForm_facebook_icone:hover {
    cursor: pointer;
}

.loginForm_label {
    grid-column-start: 1;
    grid-column-end: 4;
}

.loginForm_connect_button {
    grid-column-start: 2;
    grid-row-start: 3;
}

.loginForm_facebook_icone {
    grid-column-start: 2;
    grid-row-start: 4;
}

.loginFormItem {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 600px) {


    .loginForm {
        margin-left: 7.5vw;
        margin-top: 20vh;
        width: 80vw;
        height: 50vh;
    }

    .loginForm_connect_button {
        width: 60vw;
        grid-column-start: 1;
        grid-column-end: 4;
        justify-self: center;
    }

    .loginForm_facebook_icone {
        align-self: start;
    }


}