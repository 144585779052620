.GroceryList {
    background-color: #FFFFFF;
    width: 20vw;
    height: 45vh;
    border-radius: 2vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: grid;
    align-items: center;
}

.GroceryList_name {
    font-size: 1.2vw;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row: 1;
    font-weight: 600;
    justify-content: center;
    margin: 1em auto;
}

.GroceryList_date {
    font-size: 0.8vw;
    grid-column: 3;
    grid-row: 1;
    font-weight: 600;
    justify-content: end;
    padding: 0;
    margin: 1em auto;
}

.GroceryList_groceryItemList {
    list-style-type: none;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row: 2;
    display: grid;
    padding: 0;
    max-height: 25vh;
    overflow-y: auto;
}

.GroceryList_groceryItemList>.GroceryItem {
    display: grid;
}

.GroceryList_groceryItemList>.GroceryItem>.GroceryItemName {
    justify-self: center;
}


.GroceryList_place {
    grid-row: 3;
    grid-column-start: 1;
    grid-column-end: 3;
    font-weight: 500;
    justify-self: center;

}

.GroceryList_price {

    grid-row: 3;
    grid-column: 3;
    justify-self: center;
    font-weight: 500;
}


@media only screen and (max-width: 600px) {

    .GroceryList {
        width: 80vw;
        margin-left: 8vw;
        margin-bottom: 2vh;
    }

    .GroceryList_name {
        font-size: 6vw;
    }

    .GroceryList_date {
        font-size: 5vw;
    }

    .GroceryList_place {
        font-size: 5vw;
    }

    .GroceryList_price {
        font-size: 5vw;
    }
}