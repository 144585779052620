.HomePage_title {
    color: #1DD1A1;
    width: 100vw;
    text-align: center;
}

.HomePage_GroceryListContainer_label {
    margin-top: 10vh;
    color: #1DD1A1;
    width: 100vw;
    text-align: center;
}

.HomePage_button_create_list {
    position: absolute !important;
    left: 1vw;
    color: #fff;
    background-color: #1DD1A1;
    border: none;
    border-radius: 0.2vw;
    font-weight: 600;
    padding: auto;
    width: 8vw;
    font-size: 1.2vw;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.HomePage_button_disconect {

    position: absolute !important;
    right: 1.8vw;
    top: 12vh;
    color: #fff;
    background-color: #1DD1A1;
    border: none;
    border-radius: 0.2vw;
    font-weight: 600;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.HomePage_profile_picture {
    position: absolute !important;
    right: 3.5vw;
    top: 2vh;
    border: 2px solid #1DD1A1;
    border-radius: 3px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

@media only screen and (max-width: 600px) {


    .HomePage_title {
        font-size: 7vw;
        font-weight: bold;
        width: 100vw;
        left: 2.5vw;
        margin-top: 12vh;

    }

    .HomePage_button_create_list {
        font-size: 4vw;
        width: 40vw;
        height: 5vh;
        top: 5vh;
        left: 5vw;
    }

    .HomePage_GroceryListContainer_label {
        margin-top: 2vh;
    }

    .HomePage_profile_picture {
        right: 10vw;
        margin-top: 3vh;
    }

    .HomePage_button_disconect {
        font-size: 4vw;
        width: 30vw;
        height: 5vh;
        right: 5vw;
        top: 5vh;
    }
}