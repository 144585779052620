.GroceryItem {
    display: grid;
    width: 99%;
    padding: 0;
    height: fit-content;
}


.GroceryItemName {
    grid-column-start: 1;
    grid-column-end: 3;
    justify-self: end;
}

.GroceryQuantity {
    grid-column: 3;
    justify-self: start;
    color: #1DD1A1;
    align-self: center;
}

.activeGroceryQuantity {
    color: #1DD1A1;
    align-self: center;
    justify-self: center;
}

.GroceryItem {
    list-style-type: none;
    grid-gap: 0.5vw;
    grid-template-columns: 1fr 0.2fr 0.5fr;
    grid-template-rows: auto auto auto auto;
    padding: 0.1vw;
    display: flex;
    justify-content: space-evenly;
}


.activeGroceryItem {
    list-style-type: none;
    grid-gap: 0.5vw;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0.1vw;
}

.GroceryItem_add_button {
    grid-column: 3;
    grid-row: 1;
    justify-self: start;
    align-self:center;
    color: #fff;
    background-color: #1DD1A1;
    border: none;
    text-align: center;
    align-self: center;
    border-radius: 0.1vw;
    width: 10vw;
    height: 5vh;
    padding: auto;
    font-size: 3vh;
    border: 1px solid;
}

.GroceryItem_Substract_button {
    grid-column: 3;
    grid-row: 1;
    justify-self: end;
    align-self:center;
    color: #fff;
    background-color: #1DD1A1;
    border: none;
    border-radius: 0.1vw;
    width: 10vw;
    height: 5vh;
    text-align: center;
    font-size: 3vh;
    margin-right: 2vw;
    border: 1px solid;
}