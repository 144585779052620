.ActiveGroceryList {
    background-color: #BCF1E3;
    width: 20vw;
    border-radius: 2vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
}

.activeGroceryItemName {
    grid-column: 1;
    justify-self: center;
    align-self: center;
    overflow-wrap: anywhere;
}

.ActiveGroceryList_Label {
    grid-row: 1;
    grid-column: 2;
    font-weight: 600;
    font-size: 3vh;
    justify-self: center;
}

.ActiveGroceryList_Input {
    border: 0;
    outline: 0;
    border-bottom: 2px solid rgb(183, 185, 189);
    grid-row: 2;
    grid-column: 2;
    align-self: start;
}

.ActiveGroceryList_Input:focus {
    border-bottom: 2px solid #1DD1A1;
}

.ActiveGroceryList_Input_button {
    color: #fff;
    background-color: #1DD1A1;
    border: none;
    height: 4vh;
    width: 8vh;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 6px;
    grid-row: 2;
    grid-column: 3;
    text-align: center;
    align-self: center;
    justify-self: center;
}

.ActiveGroceryList_Share_button {
    color: #fff;
    background-color: #1DD1A1;
    border: none;
    height: 4vh;
    width: 8vh;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 6px;
    grid-row: 2;
    grid-column: 1;
    text-align: center;
    align-self: center;
    justify-self: center;
}

.ActivegroceryItemList {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    grid-row: 3;
    grid-column-start: 1;
    grid-column-end: 4;
    text-align: center;
}

.ActivegroceryItemList>li {
    display: grid;
    margin-top: 0.5vh;
    grid-template-columns: 2fr 0.5fr 1fr;
}

.ActivegroceryItemList>li>span {
    grid-column: 1;
    justify-self: end;
}


.ActiveGroceryListDeleteListBtn {
    grid-row: 4;
    grid-column: 1;
    color: #fff;
    background-color: #e63a26;
    border: none;
    padding: auto;
    border-radius: .5vw;
    font-size: 1.1vw;
    margin-left: 2vw;

}

.GroceryListDeleteListBtn {
    grid-column-start: 1;
    grid-column-end: 4;
    color: #fff;
    background-color: #e63a26;
    border: none;
    padding: auto;
    border-radius: .5vw;
    font-size: 1.1vw;
    text-align: center;
    margin-bottom: 2vh;
}

.ActiveGroceryList_End_Go_Shopping_Button {
    color: #fff;
    background-color: #1DD1A1;
    border: none;
    padding: auto;
    grid-row: 4;
    grid-column-start: 2;
    grid-column-end: 3;
    border-radius: .5vw;
    font-size: 1.1vw;
    margin-left: 5vw;
}

@media only screen and (max-width: 600px) {

    .ActiveGroceryList {

        width: 90vw;
        margin-left: 2.5vw;
        margin-bottom: 2vh;
        padding-bottom: 1vh;
    }

    .ActiveGroceryList_Label {

        grid-column-start: 1;
        grid-column-end: 4;

    }

    .ActiveGroceryList_Input {
        grid-row: 2;
        grid-column: 2;
        justify-self: center;
        align-self: center;
    }

    .ActiveGroceryList_Input_button {

        align-self: center;
        justify-self: center;
    }

    .ActiveGroceryList_End_Go_Shopping_Button {
        font-size: 5vw;
        font-weight: 600;
    }

    .ActiveGroceryListDeleteListBtn,
    .GroceryListDeleteListBtn {

        font-size: 5vw;
        font-weight: 600;
    }

}